export function initParallax()
{
    const intersectObs = new IntersectionObserver(render, { threshold: thresholdArr(100) });

    function thresholdArr(num)
    {
        const arr = [];
        const levels = num;
        while (num--)
        {
            arr[num] = num / levels;
        }

        return arr;
    }

    function render(entry)
    {
        entry = entry[0];
        if (entry.intersectionRatio > 0)
        {
            const parallaxTarget = entry.target.querySelector('.js-parallax');
            if (parallaxTarget)
            {
                parallaxTarget.style.transform = `translateX(-50%) translateY(-${(45 + (entry.intersectionRatio * 10) / 2)}%)`;
            }
        }
    }

    document.querySelectorAll('[data-parallax]').forEach((item) =>
    {
        intersectObs.observe(item);
    });
}
