// import $ from 'jquery';
// import './midnight';

export class ProspektHeader
{

    constructor()
    {
        this.header = document.querySelector('.header');
        this.otherNav = document.querySelector('.sections-with-nav__nav');
        // this.header = $('.header');
        this.scrollPosition = window.pageYOffset;
        this.ticking = false;
        this.addEventListeners();
        this.init();
    }

    addEventListeners()
    {
        window.addEventListener('scroll', function()
        {
            if (!this.ticking)
            {
                window.requestAnimationFrame(function()
                {
                    this.onScroll();
                    this.ticking = false;
                }.bind(this));
            }
        }.bind(this));
    }

    init()
    {
        // this.header.midnight({
        //     headerClass: 'header__switcher',
        //     sectionSelector: 'header',
        // }).addClass('active');
    }

    onScroll()
    {
        const currentScrollPosition = window.pageYOffset;
        // if (currentScrollPosition <= 0)
        // {
        //     this.header.classList.remove('header--not-at-top');
        // }

        if (this.scrollPosition >= currentScrollPosition || currentScrollPosition <= 0)
        {
            this.header.classList.remove('header--hide');
            if (this.otherNav) {
                this.otherNav.classList.remove('header--hide');
            }
        }
        else
        {
            this.header.classList.add('header--hide');
            if (this.otherNav) {
                this.otherNav.classList.add('header--hide');
            }
        }
        this.scrollPosition = currentScrollPosition;
    }

}