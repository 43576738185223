export class CookieBar
{

    constructor()
    {
        this.COOKIE_NAME = 'prospekt_cookies_accepted';
        this.COOKIE_DURATION = 365;

        this.bar = document.querySelector('.cookie-bar');
        if (!this.bar) return;

        this.acceptButton = this.bar.querySelector('.js-accept-cookies');
        if (!this.acceptButton) return;

        this.addEventListeners();
        this.init();
    }

    addEventListeners()
    {
        this.acceptButton.addEventListener('click', function()
        {
            this.setCookie();
            this.bar.classList.remove('slide-animate-in');
            setTimeout(() =>
            {
                this.bar.classList.remove('active');
            }, 300);
        }.bind(this));
    }

    init()
    {
        if (!this.getCookie())
        {
            this.bar.classList.add('active');
            requestAnimationFrame(() =>
            {
                this.bar.classList.add('slide-animate-in');
            });
        }
    }

    setCookie()
    {
        const d = new Date();
        d.setTime(d.getTime() + (this.COOKIE_DURATION * 24 * 60 * 60 * 1000));
        const expires = `expires=${ d.toUTCString()}`;
        document.cookie = `${this.COOKIE_NAME }=${ true };${ expires };path=/`;
    }

    getCookie()
    {
        const name = `${this.COOKIE_NAME }=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++)
        {
            let c = ca[i];
            while (c.charAt(0) == ' ')
            {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0)
            {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    }

}