export class Lazyload
{

    constructor()
    {
        this.observer = new IntersectionObserver(
            this.render.bind(this),
            { rootMargin: '100px' }
        );

        document.querySelectorAll('[data-lazyload]').forEach((item) =>
        {
            item.addEventListener('load', this.onLoad.bind(this));
            this.observer.observe(item);
        });
    }

    render(entries)
    {
        entries.forEach(entry =>
        {
            if (entry.isIntersecting && entry.intersectionRatio > 0)
            {
                this.startLoad(entry.target);
            }
        });

    }

    startLoad(target)
    {
        if (target.dataset.src)
        {
            target.setAttribute('src', target.dataset.src);
            target.removeAttribute('data-src');
        }

        if (target.dataset.srcset)
        {
            target.setAttribute('srcset', target.dataset.srcset);
            target.removeAttribute('data-srcset');
        }

        if (target.dataset.sizes)
        {
            target.setAttribute('sizes', target.dataset.sizes);
            target.removeAttribute('data-sizes');
        }

        target.classList.add('lazyloading');
    }

    onLoad(event)
    {
        event.target.classList.add('lazyloaded');
        event.target.classList.remove('lazyloading');
        this.observer.unobserve(event.target);
    }

}
