export function initScroll()
{


    const scrollButtons = document.querySelectorAll('[data-scroll]');

    scrollButtons.forEach((button) => button.addEventListener('click', function(e)
    {
        const offset = (this.dataset.scrollSide) ? this.dataset.scrollSide : 'top';
        const target = document.querySelector(this.dataset.scrollTarget);
        if (target)
        {
            e.preventDefault();
            const offsetTop = window.pageYOffset + target.getBoundingClientRect().y;
            const y = (offset === 'bottom') ? offsetTop+ target.clientHeight : offsetTop;

            if (window.smoothScrollSupported)
            {
                window.scrollTo({
                    top: y,
                    left: 0,
                    behavior: 'smooth',
                });
            }
            else
            {
                window.scrollTo(0, y);
            }
        }
    }));
}