// import $ from 'jquery';
import debounce from 'lodash/debounce';

export class ProspektModal
{
    constructor()
    {
        this.htmlElement = document.querySelector('html');
        this.bodyElement = document.querySelector('body');
        this.addEventListeners();

        let modals = [
            'contact',
            'showreel',
        ];
        let modalPath = document.location.hash.substr(1);
        if (modals.includes(modalPath))
        {
            this.target = modalPath;
            this.clickX = window.innerWidth / 2;
            this.clickY = window.innerHeight / 2;
            this.openModal(modalPath);
        }
        else
        {
            this.closeModal();
        }
    }

    addEventListeners()
    {
        window.addEventListener('resize', debounce(function()
        {
            if (this.target)
            {
                this.calculateIris();
            }
        }.bind(this), 100));

        document.querySelectorAll('a[href^="#modal-"]').forEach((element) =>
        {
            element.addEventListener('click', async function(e)
            {
                e.preventDefault();
                // Close any active modal
                await this.closeModal();
                this.button = (e.currentTarget) ? e.currentTarget : e.target;
                this.target = this.button.getAttribute('href').substring('7');
                this.clickX = e.clientX;
                this.clickY = e.clientY;
                await this.openModal();
            }.bind(this));
        });


        document.querySelectorAll('.js-modal-open').forEach((element) =>
        {
            element.addEventListener('click', async function(e)
            {
                e.preventDefault();
                await this.closeModal();
                this.button = (e.currentTarget) ? e.currentTarget : e.target;
                this.target = this.button.dataset.target;
                this.clickX = e.clientX;
                this.clickY = e.clientY;
                await this.openModal();
            }.bind(this));
        });

        document.querySelectorAll('.js-modal-close').forEach((element) =>
        {
            element.addEventListener('click', async function(e)
            {
                let button = (e.currentTarget) ? e.currentTarget : e.target;
                await this.closeModal(button.dataset.target);
            }.bind(this));
        });
    }

    transition(element, timeout, {to, using, from, useTransitions = true} = {})
    {
        return new Promise((resolve, reject) =>
        {
            const change = () =>
            {
                if (to)
                {
                    element.classList.add(to);
                }
                else if (from)
                {
                    element.classList.remove(from);
                }
            };

            if (useTransitions)
            {
                element.classList.add(using);

                // Double rAF to ensure a frame happens before the class is added.
                requestAnimationFrame(() => requestAnimationFrame(change));

                setTimeout(() =>
                {
                    element.classList.remove(using);
                    resolve();
                }, timeout);
            }
            else
            {
                change();
                resolve();
            }
        });
    }

    buildIris()
    {
        this.irisElement = document.createElement('div');
        this.irisElement.setAttribute('data-iris', this.target);
        this.irisElement.classList.add('js-iris');
        this.irisElement.classList.add('iris');
        document.querySelector('body').appendChild(this.irisElement);
    }

    calculateIris()
    {
        let baseSize = Math.max(window.innerHeight - 50, window.innerWidth - 50);
        let scale = Math.sqrt(Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2)) / baseSize;
        let startPositionX = this.clickX;
        let startPositionY = this.clickY;
        let endPositionX = (window.innerWidth / 2) - this.clickX;
        let endPositionY = (window.innerHeight / 2) - this.clickY;

        document.querySelector('#iris-styles').innerHTML = `
            .iris {
                top: ${startPositionY}px;
                left: ${startPositionX}px;
            }
            .iris--active {
                transform: translate(${endPositionX}px, ${endPositionY}px) scale(${scale});
            }
        `;
    }

    async openIris()
    {
        this.buildIris();
        this.calculateIris();
        this.irisElement.classList.add(`iris--${this.target}`);
        await this.transition(this.irisElement, 300, { to: 'iris--active', useTransitions: true })
        await this.irisFullyOpened();
    }

    async irisFullyOpened()
    {
        this.htmlElement.dataset.scrollTop = window.scrollY;
        this.htmlElement.style.top = '-' + window.scrollY + 'px';
        this.htmlElement.classList.add('modal-active');
        this.htmlElement.classList.add(`modal-active--${this.target}`);
        this.modalElement.classList.add('modal--active');

        if (this.target === 'showreel')
        {
            let showreelVideo = document.querySelector('.js-showreel');
            showreelVideo.setAttribute('src', showreelVideo.dataset.showreel);
        }

        await this.transition(this.modalElement, 300, { to: 'modal--fade-in', useTransitions: true });
    }

    irisClosed()
    {
        document.querySelector('body').removeChild(this.irisElement);
        this.irisElement = null;
        this.modalElement = null;
        this.target = null;
    }

    async onModalHidden()
    {
        this.modalElement.classList.remove('modal--active');
        await this.transition(this.irisElement, 300, { from: 'iris--active', useTransitions: true });
        this.irisClosed();
    }

    async openModal()
    {
        this.scrollTop = this.htmlElement.dataset.scrollTop ? this.htmlElement.dataset.scrollTop : window.scrollY;
        this.modalElement = document.querySelector(`.js-modal[data-modal="${this.target}"]`);

        if (this.modalElement)
        {
            await this.openIris();
        }
    }

    async closeModal(target = this.target) {
        if (target === 'showreel')
        {
            let showreelVideo = document.querySelector('.js-showreel');
            showreelVideo.setAttribute('src', '');
        }

        if (this.modalElement)
        {
            let scrollTop = this.htmlElement.dataset.scrollTop;
            this.htmlElement.classList.remove(`modal-active--${target}`);
            this.htmlElement.classList.remove('modal-active');
            this.htmlElement.style.top = '';

            if (scrollTop)
            {
                this.htmlElement.setAttribute('data-scrollTop', '');
                window.scrollTo(0, scrollTop);
            }

            await this.transition(this.modalElement, 300, { from: 'modal--fade-in', useTransitions: true })
            await this.onModalHidden();
        }
    }
}
