export function initProjectHover() 
{
    var items = document.querySelectorAll('.project-item');
    window.gridItemTouches = {};
    if (items.length > 0)
    {
        items.forEach((item) => 
        {
            item.touchCount = 0;
            item.addEventListener('touchstart', function(e) 
            {
                clearOtherTouches(this);
                this.touchCount = this.touchCount + 1;
            }, {passive: true});
            item.addEventListener('click', function(e) 
            {
                if (this.touchCount % 2 === 1) 
                {
                    e.preventDefault();
                    e.stopPropagation();
                }
            });
        });
    }   
}

function clearOtherTouches(touchedItem = null)
{
    document.querySelectorAll('.project-item').forEach((item) => 
    {
        if (item !== touchedItem)
        {
            item.touchCount = 0;
        }
    });
}