import '@babel/polyfill';
import { CookieBar } from './cookie-bar';
import { ProspektGrid } from './grids';
import { initProjectHover } from './project-item';
import { initSliders } from './sliders';
import { ProspektHeader } from './header';
import { initParallax } from './parallax';
import { initScroll } from './scroll';
import { ProspektModal } from './modals';
import { Lazyload } from './lazyload';
import canSmoothScroll from './canSmoothScroll';

document.addEventListener("DOMContentLoaded", function(){
    init();
});

function init()
{
    new Lazyload();
    new ProspektHeader();
    new CookieBar();
    initProjectHover();

    const triggers = document.querySelectorAll('.js-trigger-menu');
    for (let i = 0; i < triggers.length; i++)
    {
        triggers[i].addEventListener('click', triggerMenu);
    }

    function triggerMenu(e)
    {
        e.preventDefaut();
        document.querySelector('html').classList.toggle('menu-active');
        document.querySelector('#menu').classList.toggle('header__nav--active');
    }
    window.smoothScrollSupported = canSmoothScroll();
    new ProspektGrid();
    new ProspektModal();
    initScroll();
    initSliders();
    initParallax();

    var beforeAfters = document.querySelectorAll('.js-before-after');
    if (beforeAfters.length > 0)
    {
        import(/* webpackChunkName: "before-after" */'./before-after').then(
            ({BeforeAfter}) =>
            {
                [...beforeAfters].forEach((beforeAfter) => beforeAfter.beforeAfter = new BeforeAfter(beforeAfter));
            }
        );
    }

    var sectionsWithNavs = document.querySelectorAll('[data-sections-with-nav]');
    if (sectionsWithNavs.length > 0)
    {
        import(/* webpackChunkName: "sections-with-nav" */'./sections-with-nav').then(
            ({SectionsWithNav}) =>
            {
                [...sectionsWithNavs].forEach((sectionsWithNav) => sectionsWithNav.sectionsWithNav = new SectionsWithNav(sectionsWithNav));
            }
        );
    }
}