function addLazyLoadListener(slide, image)
{
    image.addEventListener('load', function (event) {
        const target = event.target;
        requestAnimationFrame(() => {
            target.classList.add('lazyloaded');
        });
    })
}

const settings = [];

settings['clients'] = {
    preloadImages: false,
    lazy: {
        loadPrevNext: true,
    },
    on: {
        lazyImageLoad: (slide, image) => addLazyLoadListener(slide, image)
    },
    loop: true,
    slidesPerView: 2,
    autoplay: {
        delay: 2000,
    },
    breakpointsInverse: true,
    breakpoints: {
        560: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
        1400: {
            slidesPerView: 5,
        },
        1600: {
            slidesPerView: 6,
        }
    }
}

settings['featured'] = {
    preloadImages: false,
    lazy: {
        loadPrevNext: true,
    },
    on: {
        lazyImageLoad: (slide, image) => addLazyLoadListener(slide, image)
    },
    loop: true,
    autoplay: {
        delay: 10000,
    },
    threshold: 50,
    autoHeight: true,
    pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
        bulletElement: "button",
    },
    navigation : {
        prevEl: ".featured__previous",
        nextEl: ".featured__next",
    }
}


export function initSliders() {
    var sliders = document.querySelectorAll('.swiper-container');
    if (sliders.length > 0)
    {
        import(/* webpackChunkName: "swiper" */'./custom-swiper').then(
            ({default: Swiper}) =>
            {
                [...sliders].forEach((slider) => slider.slider = new Swiper(slider, (settings[slider.dataset.swiper]) ? settings[slider.dataset.swiper] : JSON.parse(slider.dataset.swiper)));
            }
        );
    }
}
