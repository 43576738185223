export default function canSmoothScroll()
{
    let supports = false;
    try
    {
        const div = document.createElement('div');
        div.scrollTo({
            top: 0,
            get behavior()
            {
                supports = true;

                return 'smooth';
            },
        });
    }
    catch (err)
    {
        // eslint-disable-next-line no-console
        console.log('no smooth scroll');
    }

    return supports;
}