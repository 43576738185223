import imagesLoaded from 'imagesloaded';

export class ProspektGrid
{
    constructor()
    {
        this.ACTIVE_CLASS = 'filter-active';
        this.CONFIG = {
            default: {
                layoutMode: 'packery',
                columnWidth: '.grid-sizer',
                itemSelector: '.projects-grid__item',
            }
        };

        this.grids = document.querySelectorAll('[data-isotope]');
        this.filters = document.querySelectorAll('[data-filter]');

        if (this.grids.length > 0)
        {
            this.addEventListeners();
            this.init()
        }
    }

    addEventListeners()
    {
        window.addEventListener('hashchange', this.hashChange.bind(this));
        [...this.filters].forEach((filter) => {
            filter.addEventListener('click', this.onClick.bind(this));
        });
    }

    init()
    {
        Promise.all([
            import(/* webpackChunkName: "isotope-layout" */'isotope-layout'),
            import(/* webpackChunkName: "isotope-packery" */'isotope-packery'),
        ]).then(([{default: Isotope}, {default: packery}]) => {
            [...this.grids].forEach((grid, index) => {
                imagesLoaded( grid, function( instance ) {
                    let filter = this.getHashFilter();
                    if (filter)
                    {
                        let y = grid.parentElement.parentElement.offsetTop;
                        if (window.smoothScrollSupported)
                        {
                            window.scrollTo({
                                top: y,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }
                        else
                        {
                            window.scrollTo(0, y);
                        }
                    }
                    filter = (filter) ? filter : '*';

                    grid.isotopeGrid = new Isotope(grid, {
                        ...this.CONFIG['default'],
                        filter
                    });
                    this.changeDescription(filter);
                    this.organiseActiveClasses(filter);
                }.bind(this));
            });
        });
    }


    getHashFilter() {
        var hash = location.hash;
        // get filter=filterName
        var matches = location.hash.match( /filter=([^&]+)/i );
        if (matches && matches[1])
        {
            let filter = matches[1]
            filter = (filter === '*') ? filter : `.${filter}`;
            return decodeURIComponent( filter );
        }
    }

    changeFilter(filter)
    {
        let filterElement = document.querySelector('[data-filter="'+filter+'"]')
        if (filter && filterElement)
        {
            setTimeout(() => {
                this.simulateClick(filterElement);
            }, 1);
        }
    }

    simulateClick(element)
    {
        var evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });
        element.dispatchEvent(evt);
    }

    onClick(e)
    {
        let filter = e.target.dataset.filter;
        let grid = e.target.dataset.grid;
        let gridElement = document.querySelector(grid);

        if (!gridElement) return;
        if (!gridElement.isotopeGrid) return;

        gridElement.isotopeGrid.arrange({
            ...this.CONFIG['default'],
            filter
        });
        this.organiseActiveClasses(filter)
        let filterWithoutClass = (filter === '*') ? filter : filter.substring(1);
        location.hash = 'filter=' + encodeURIComponent( filterWithoutClass );
    }




    organiseActiveClasses(filter)
    {
        let oldFilter = document.querySelector(`[data-filter].${this.ACTIVE_CLASS}`);
        if (oldFilter) oldFilter.classList.remove(this.ACTIVE_CLASS);
        let newFilter = document.querySelector('[data-filter="'+ filter +'"]');
        if (newFilter) newFilter.classList.add(this.ACTIVE_CLASS);
    }

    hashChange()
    {
        let filter = this.getHashFilter();
        this.changeFilter(filter);
        this.changeDescription(filter)
    }

    changeDescription(filter)
    {
        let termDescriptions = document.querySelectorAll('[data-term]');
        [...termDescriptions].forEach((item) => {
            item.classList.add('u-hidden');
            if (item.dataset.term === filter)
            {
                item.classList.remove('u-hidden');
            }
        });
    }
}